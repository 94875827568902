<template>
  <div class="card-popup" ref="cardPopup">
    <div class="img-container">
      <img ref="img" :src="require('@/assets/projects/' + image)" />
    </div>

    <div class="card-content-container" ref="content">
      <div class="card-circles">
        <div v-for="(r, i) in 5" :key="`a` + i">
          <CardRow :presentPercentage="0.6"></CardRow>
        </div>
      </div>

      <i @click="closePopup" class="material-icons-outlined">{{ "close" }}</i>

      <div class="name" ref="name">{{ name }}</div>
      <div class="company" ref="company">{{ company }}</div>
      <div class="desc" ref="desc">{{ desc }}</div>
      <div class="tools" ref="tools">
        <CardTools :toolIndexes="toolIndexes"></CardTools>
      </div>
    </div>
  </div>
</template>

<script>
import CardRow from './CardRow.vue';
import CardTools from './ProjectTools.vue';

export default {
  components: { CardRow, CardTools },
  props:["name","company", "desc", "image", "toolIndexes"],
  data () {
    return {
    }
  },
  methods: {
    closePopup () {
      this.gsap.fromTo(this.$refs.cardPopup, {
        x: 0,
        opacity: 1
      }, {
        x: -200,
        opacity: 0,
        duration: .2,
        ease: 'power3.in'
      });

      setTimeout(() => {
        this.$emit('closeProjectPopup')
      }, 500)
    }
  }, mounted () {    
    this.gsap.fromTo(this.$refs.cardPopup, {
      x: 200,
      opacity: 0
    }, {
      x: 0,
      opacity: 1,
      duration: .2,
      ease: 'power.in'
    });
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.card-popup {
  @include flex-center;
  @include shadow;

  transition: all 1 ease-in-out;
  z-index: 1500;
  background: linear-gradient(
    25deg,
    rgb(0, 255, 234) 0%,
    rgba(0, 65, 255) 100%
  );

  @media only screen and (max-width: 600px) {
    width: 95vw;
    height: 60vw;
    border-radius: .5rem;
  }
  @media only screen and (min-width: 600px) {
    width: 83vw;
    height: 53vw;
    margin-left: 2.5rem;
  }

  @media only screen and (min-width: 800px) {
    width: 79vw;
    height: 52vw;
    margin-left: 2.5rem;
  }
  @media only screen and (min-width: 1000px) {
    width: 70vw;
    height: 42vw;
  }

  @media only screen and (min-width: 1200px) {
    width: 55vw;
    height: 33vw;
  }
  @media only screen and (min-width: 1400px) {
    width: 50vw;
    height: 30vw;
  }

  .img-container {
    @include flex-center;
    width: 60%;
    height: 100%;

    img {
      width: 22rem;
    }
  }

  .card-content-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    border-radius: 0 1rem 1rem 0;
    right: 0;
    width: 40%;
    height: 100%;
    background: rgb(255, 255, 255);
    padding: 1.5rem;

    @media only screen and (max-width: 600px) {
      padding: .5rem;
      border-radius: 0 .5rem .5rem 0;
    }

    .card-circles {
      display: flex;
      position: absolute;
      top: 0.4rem;
      right: 0.4rem;
    }
    .name {
      color: $primary;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.1;
      text-align: right;
      margin: 0 0 1rem 0;

      @media only screen and (max-width: 600px) {
        font-size: 1.1rem;
        margin: 0 0 0.3rem 0;
      }
      @media only screen and (min-width: 600px) {
        font-size: 1.8rem;
      }
      @media only screen and (min-width: 768px) {
        font-size: 2rem;
      }
      @media only screen and (min-width: 992px) {
        font-size: 2.2rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 2.4rem;
      }
    }
    .company {
      color: $gray-light;
      font-size: 2.2rem;
      text-align: right;
      line-height: 0.9;
      font-family: "Saira Condensed", sans-serif;
      margin: 0 0 0.6rem 0;

      @media only screen and (max-width: 600px) {
        font-size: .9rem;
        margin: 0 0 0.3rem 0;
      }
      @media only screen and (min-width: 600px) {
        font-size: 1.2rem;
      }
      @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
      @media only screen and (min-width: 992px) {
        font-size: 1.7rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 1.9rem;
      }
    }
    .desc {
      color: $gray-dark;
      font-size: 1.5rem;
      font-weight: 100;
      text-align: right;
      line-height: 1;
      text-transform: uppercase;
      margin: 0 0 0.6rem 0;

      @media only screen and (max-width: 600px) {
        font-size: .7rem;
        margin: 0 0 0.3rem 0;
      }
      @media only screen and (min-width: 600px) {
        font-size: 1.2rem;
      }
      @media only screen and (min-width: 768px) {
        font-size: 1.3rem;
      }
      @media only screen and (min-width: 992px) {
        font-size: 1.6rem;
      }
      @media only screen and (min-width: 1200px) {
        font-size: 1.9rem;
      }
    }
  }
}

i {
  position: absolute;
  padding: 0.5rem;
  top: 1.2rem;
  right: 1.2rem;
  cursor: pointer;
  border-radius: 50%;
  z-index: 3000;
  color: rgb(255, 255, 255);
  background-color: $primary;
  font-size: 1.5rem;

  @media only screen and (max-width: 600px) {
    padding: 0.3rem;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.1rem;
  }
 

  &:hover {
    color: rgb(0, 0, 0);
    background-color: rgba(175, 216, 255, 0.4);
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
  }
}
</style>