
<template>
  <div class="bg-row-container">
    <div v-for="(c, i) in rows" :key="i">
      <!-- isPresent, isBlinking, duration, opacity -->
      <MapCircle
        :isPresent="c[0]"
        :isBlinking="c[1]"
        :duration="c[2]"
        :opacity="c[3]"
      ></MapCircle>
    </div>
  </div>
</template>

<script>
import MapCircle from "./MapCircle.vue"
export default {
  components: {
    MapCircle
  },
  props: ['presentPercentage', 'present'],
  data () {

    return {
      rows: [],
      rowsLength: 15,
    }
  },
  created () {
    for (let i = 0; i < this.rowsLength; i++) {
      this.rows[i] = [this.setPresent(i), this.setBlinking(), this.setDuration(), this.setOpacity()]
    }
  },
  methods: {
    setPresent (index) {
      return this.present[index] === 0 ? false : true
    },
    setBlinking () {
      return Math.random() < 1
    },
    setDuration () {
      var duration = ((Math.random() * 10) + 3).toFixed(1)
      return duration
    },
    setOpacity () {
      return ((Math.random() * .8) + .3).toFixed(1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.bg-row-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>