import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import VueCarousel from 'vue-carousel'
import VueTilt from 'vue-tilt.js'

import vuelidate from 'vuelidate'
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
Vue.use(vuelidate)
Vue.use(vuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.foundation6,
  messages: { required: '{attribute} field is required' },
  attributes: {
    // maps form keys to actual field names
    email: 'Email',
    name: 'Name',
    subject: 'Subject',
    message: 'Message'
  }
})

import { Field, Input, Button } from 'buefy'
import 'buefy/dist/buefy.css'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

Vue.use(VueTilt)
Vue.use(VueCarousel)
Vue.use(Field)
Vue.use(Input)
Vue.use(Button)

import {gsap} from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
// import {} from 'gsap'

// import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)

// gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
Vue.mixin({
  created: function() {
    this.gsap = gsap
    this.scrollTrigger = ScrollTrigger
    // this.ScrollToPlugin = ScrollToPlugin
    // console.log("🚀 ~ file: main.js ~ line 16 ~ this.ScrollToPlugin", this.ScrollToPlugin)
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
