
<template>
  <div class="bg-row-container">
    <div v-for="(c, i) in rows" :key="i">
      <!-- isPresent, isBlinking, duration, opacity -->
      <BgCircle
        :isPresent="c[0]"
        :isBlinking="c[1]"
        :duration="c[2]"
        :opacity="c[3]"
      ></BgCircle>
    </div>
  </div>
</template>

<script>
import BgCircle from "./BgCircle.vue"
export default {
  components: {
    BgCircle
  },
  props: ['presentPercentage'],
  data () {
    return {
      rows: [],
      rowsLength: 11
    }
  },
  created () {
    for (let i = 0; i < this.rowsLength; i++) {
      // isPresent, isBlinking, duration, opacity
      this.rows[i] = [this.setPresent(), this.setBlinking(), this.setDuration(), this.setOpacity()]
    }
  },
  methods: {
    setPresent () {
      return Math.random() < this.presentPercentage
    },
    setBlinking () {
      return Math.random() < 1
    },
    setDuration () {
      var duration = ((Math.random() * 10) + 3).toFixed(1)
      return duration
    },
    setOpacity () {
      return ((Math.random() * .5) + .1).toFixed(1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.bg-row-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>