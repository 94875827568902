<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import '../src/styles/locomotive-scroll.css'

export default {
  name: 'App',
  components: {

  },
  data () {
    return {
    }
  },
  mounted () {
    this.setMobileScreen()
    this.isMobileScreen()
  },
  methods: {
    setMobileScreen () {
      var vw = window.innerWidth;

      // check if width lass 600px or detect a mobile device
      if (vw < 600 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.$store.state.mobileScreen = true
      } else {
        this.$store.state.mobileScreen = false
      }
    },
    isMobileScreen () {
      window.addEventListener('resize', () => {
        this.setMobileScreen()
      })
    }
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;500;700;900&family=Saira+Condensed:wght@100;300;600;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

@import "@/styles/mixin.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 62.5%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
}

.router-enter-from {
  opacity: 0;
  transition: translateX(20em);
}
.router-anter-active {
  transition: all 5s ease-out;
}
.router-leave-to {
  opacity: 0.5;
  transition: translateX(-20em);
}

.a {
  border: 1px solid red;
}
.b {
  border: 1px solid rgb(0, 225, 255);
}
.c {
  border: 1px solid rgb(1, 211, 19);
}
.d {
  border: 1px solid rgb(68, 0, 255);
}

.textarea {
  resize: none !important;
  @media only screen and (max-width: 600px) {
    height: 8rem !important;
  }
}
</style>