<template>
  <section @click.self="closePopup" ref="sectionProjects" id="sectionProjects">
    <h1 class="title">PROJECTS</h1>

    <div @click.self="closePopup" class="projects-rows" ref="proj">
      <ProjectCardPopup
        :name="projects[popupIndex].name"
        :company="projects[popupIndex].company"
        :desc="projects[popupIndex].desc"
        :image="projects[popupIndex].image"
        :toolIndexes="projects[popupIndex].indexes"
        ref="card"
        v-if="popup"
        class="project-card-popup"
        v-on:closeProjectPopup="closePopup"
      ></ProjectCardPopup>

      <div class="row-container">
        <ProjectCard
          :id="projects[0].id"
          :image="projects[0].image"
          v-on:openCardPopup="openPopup($event)"
        />
      </div>

      <div class="row-container">
        <ProjectCard
          :id="projects[1].id"
          :image="projects[1].image"
          v-on:openCardPopup="openPopup($event)"
        />
        <ProjectCard
          :id="projects[2].id"
          :image="projects[2].image"
          v-on:openCardPopup="openPopup($event)"
        />
      </div>
      <div class="row-container">
        <ProjectCard
          :id="projects[3].id"
          :image="projects[3].image"
          v-on:openCardPopup="openPopup($event)"
        />
        <ProjectCard
          :id="projects[4].id"
          :image="projects[4].image"
          v-on:openCardPopup="openPopup($event)"
        />
        <ProjectCard
          :id="projects[5].id"
          :image="projects[5].image"
          v-on:openCardPopup="openPopup($event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ProjectCard from '../components/ProjectCard.vue';
import ProjectCardPopup from '../components/ProjectCardPopup.vue';

export default {
  data () {
    return {
      popup: false,
      popupIndex: null,
      projects: [{
        id: 0,
        name: "MSC TIME TRACKER",
        company: "Multy Service Center",
        desc: "full stack application",
        image: 'msc.png',
        indexes: [1, 4, 5, 10, 20, 21, 27, 19]
      },
      {
        id: 1,
        name: "SKCAC FORM AUTOMATION",
        company: "SKCAC",
        desc: "full stack application",
        image: 'skcac.png',
        indexes: [1, 4, 5, 10, 20, 21, 27, 19]
      },
      {
        id: 2,
        name: "DASHBOARD ANALYTICS",
        company: "Tapped Technologies, LLC",
        desc: "full stack application",
        image: 'db.png',
        indexes: [1, 4, 5, 22, 23, 24, 25, 26]
      },
      {
        id: 3,
        name: "NOTE MAKER",
        company: "Independent Project",
        desc: "JavaFX Desktop Application",
        image: 'notes.png',
        indexes: [2, 5, 28]
      },
      {
        id: 4,
        name: "LISTIT ADS MANAGER",
        company: "Independent Project",
        desc: "VueJS, Java Spring Boot Application",
        image: 'lystit.png',
        indexes: [0, 2, 3, 4, 5, 1, 11, 16]
      },
      {
        id: 5,
        name: "FILE ATTRIBUTE MANAGER",
        company: "Independent Project",
        desc: "WPF Application",
        image: 'sw.png',
        indexes: [17, 18, 15]
      }]
    }
  },
  components: {
    ProjectCard, ProjectCardPopup
  },
  mounted () {
  },
  methods: {
    closePopup () {
      this.popup = false
    },
    openPopup (e) {
      this.popup = true
      this.popupIndex = e
      console.log("🚀 ~ this.popup", e)
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

section {
  @include section-layout;
  @include flex-center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  height: 100vh;
  width: 100vw;
  z-index: 1000;

  .title {
    @include section-title;
  }

  .projects-rows {
    @include flex-center;
    flex-direction: column;

    .project-card-popup {
      position: absolute;
      left: 50%;
      top: 155%;
      transform: translate(-50%, -50%);
    }

    .row-container {
      @include flex-center;
    }
  }
}
</style>