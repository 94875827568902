<template>
  <div class="header" ref="links">
    <div class="navbar-links">
      <div class="navbar-end start">
        <div class="gradient"></div>
        <div class="navbar-end-left"></div>
        <div
          ref="navbarEndBottom"
          class="navbar-end-top-bottom shiftBorder"
          :class="[home ? bottomActiveClass : notActiveClass]"
        ></div>
      </div>

      <div
        @click="setNewTab('home')"
        :class="[]"
        class="navbar-btn home"
        ref="homelink"
      >
        <div class="navbar-btn-border">
          <div
            class="navbar-btn-left"
            :class="[home ? navbarBtnActive : navbarBtnNotActive]"
          ></div>
          <div
            ref="btnHome"
            class="navbar-btn-right-not-active"
            :class="[home ? navbarBtnRightActive : navbarBtnRightNotActive]"
          ></div>
        </div>

        <!-- <router-link to="/" tag="div"> -->
        <div class="navbar-btn-container">
          <div ref="circleHome" class="cicle cirle-active"></div>
          <div class="name">Home</div>
        </div>
        <!-- </router-link> -->
      </div>

      <div
        @click="setNewTab('projects')"
        :class="[]"
        class="navbar-btn projects"
      >
        <div class="navbar-btn-border">
          <div
            class="navbar-btn-left"
            :class="[projects ? navbarBtnActive : navbarBtnNotActive]"
          ></div>
          <div
            ref="btnProjects"
            class="navbar-btn-right-not-active"
            :class="[projects ? navbarBtnRightActive : navbarBtnRightNotActive]"
          ></div>
        </div>

        <!-- <router-link to="/projects" tag="div"> -->
        <div class="navbar-btn-container">
          <div ref="circleProjects" class="cicle"></div>
          <div class="name">Projects</div>
        </div>
        <!-- </router-link> -->
      </div>

      <div @click="setNewTab('tools')" class="navbar-btn tools" ref="toolslink">
        <div class="navbar-btn-border">
          <div
            class="navbar-btn-left"
            :class="[tools ? navbarBtnActive : navbarBtnNotActive]"
          ></div>
          <div
            ref="btnTools"
            class="navbar-btn-right-not-active"
            :class="[tools ? navbarBtnRightActive : navbarBtnRightNotActive]"
          ></div>
        </div>

        <!-- <router-link to="/tools" tag="div"> -->
        <div class="navbar-btn-container">
          <div ref="circleTools" class="cicle"></div>
          <div class="name">Tools</div>
        </div>
        <!-- </router-link> -->
      </div>

      <div
        @click="setNewTab('contacts')"
        class="navbar-btn contacts"
        ref="contactslink"
      >
        <div class="navbar-btn-border">
          <div
            class="navbar-btn-left"
            :class="[contacts ? navbarBtnActive : navbarBtnNotActive]"
          ></div>
          <div
            ref="btnContacts"
            class="navbar-btn-right-not-active"
            :class="[contacts ? navbarBtnRightActive : navbarBtnRightNotActive]"
          ></div>
        </div>

        <!-- <router-link to="/contacts" tag="div"> -->
        <div class="navbar-btn-container">
          <div ref="circleContacts" class="cicle"></div>
          <div class="name">Contacts</div>
        </div>
        <!-- </router-link> -->
      </div>

      <div class="navbar-end end">
        <div class="gradient rotate180"></div>
        <div class="navbar-end-left"></div>
        <div
          class="navbar-end-top-bottom"
          :class="[contacts ? topActiveClass : notActiveClass]"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      home: true,
      projects: false,
      tools: false,
      contacts: false,

      navbarBtnActive: "navbar-btn-left-active",
      navbarBtnNotActive: "navbar-btn-left",

      navbarBtnRightNotActive: "navbar-btn-right-not-active",
      navbarBtnRightActive: "navbar-btn-right-active",

      topActiveClass: "navbar-end-right-top-active",
      bottomActiveClass: "navbar-end-right-bottom-active",
      notActiveClass: "navbar-end-not-active",
    }
  },
  mounted () {
    this.borderCurrent(this.$refs.btnHome)
    this.borderNext(this.$refs.btnProjects)
    this.borderFlat(this.$refs.btnTools)
    this.borderFlat(this.$refs.btnContacts)
  },
  methods: {
    fillCircle (el) {
      this.$refs.circleHome.style.backgroundColor = "white"
      this.$refs.circleProjects.style.backgroundColor = "white"
      this.$refs.circleTools.style.backgroundColor = "white"
      this.$refs.circleContacts.style.backgroundColor = "white"

      el.style.backgroundColor = "#0064ff"
    },
    borderNext (el) {
      this.borderCurrent(el)
      el.style.borderBottom = "4px solid #509cff"
      el.style.borderRight = "4px solid #509cff"
      el.style.borderRadius = "0 0 30px 0"
    },
    borderPrev (el) {
      this.borderCurrent(el)
      el.style.borderTop = "4px solid #509cff"
      el.style.borderRight = "4px solid #509cff"
      el.style.borderRadius = "0 30px 0 0"
    },
    borderCurrent (el) {
      el.style.border = "none"
      el.style.borderRadius = "0"
    },
    borderFlat (el) {
      this.borderCurrent(el)
      el.style.borderRight = "4px solid #509cff"
      el.style.borderRadius = "0"
    },
    setActiveTab (activeTab) {
      this.home = false
      this.projects = false
      this.tools = false
      this.contacts = false

      if (activeTab === "home") this.home = true
      if (activeTab === "projects") this.projects = true
      if (activeTab === "tools") this.tools = true
      if (activeTab === "contacts") this.contacts = true
    },
    setNewTab (activeTab) {
      this.setActiveTab(activeTab)
      this.$emit('activeTabInNavBar', activeTab)

      switch (activeTab) {
        case "home":
          this.borderCurrent(this.$refs.btnHome)
          this.borderNext(this.$refs.btnProjects)
          this.borderFlat(this.$refs.btnTools)
          this.borderFlat(this.$refs.btnContacts)
          this.fillCircle(this.$refs.circleHome)
          this.$refs.circleHome.classList.add('animate__animated', 'animate__shakeX');
          this.$refs.circleProjects.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleTools.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleContacts.classList.remove('animate__animated', 'animate__shakeX');
          break;
        case "projects":
          this.borderCurrent(this.$refs.btnProjects)
          this.borderPrev(this.$refs.btnHome)
          this.borderNext(this.$refs.btnTools)
          this.borderFlat(this.$refs.btnContacts)
          this.fillCircle(this.$refs.circleProjects)
          this.$refs.circleProjects.classList.add('animate__animated', 'animate__shakeX');
          this.$refs.circleHome.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleTools.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleContacts.classList.remove('animate__animated', 'animate__shakeX');
          break;
        case "tools":
          this.borderCurrent(this.$refs.btnTools)
          this.borderPrev(this.$refs.btnProjects)
          this.borderNext(this.$refs.btnContacts)
          this.borderFlat(this.$refs.btnHome)
          this.fillCircle(this.$refs.circleTools)
          this.$refs.circleTools.classList.add('animate__animated', 'animate__shakeX');
          this.$refs.circleProjects.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleHome.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleContacts.classList.remove('animate__animated', 'animate__shakeX');
          break;
        case "contacts":
          this.borderCurrent(this.$refs.btnContacts)
          this.borderPrev(this.$refs.btnTools)
          this.borderFlat(this.$refs.btnHome)
          this.borderFlat(this.$refs.btnProjects)
          this.fillCircle(this.$refs.circleContacts)
          this.$refs.circleContacts.classList.add('animate__animated', 'animate__shakeX');
          this.$refs.circleProjects.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleTools.classList.remove('animate__animated', 'animate__shakeX');
          this.$refs.circleHome.classList.remove('animate__animated', 'animate__shakeX');
          break;
        default:

      }
    }
  },
}
</script>

<style lang="scss">
@import "@/styles/mixin.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

.sidebar {
  z-index: 2000;
  position: fixed;
  width: 3.9em;
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 10px rgb(190, 190, 190);

  @media only screen and (min-width: 600px) {
    display: none;
  }
}
.header {
  position: fixed;
  height: 100%;
  width: 8vh;
  background-color: white;
  z-index: 2000;

  @media only screen and (max-width: 600px) {
    display: none;
  }

  .navbar-links {
    padding-left: 10px;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: center;
    align-items: space-between;
    color: rgb(46, 46, 46);
    z-index: 100;

    div.navbar-end {
      position: relative;
      display: flex;
      height: 20%;
      width: 100%;

      .navbar-end-left {
        @include hundred;
      }

      .shiftBorder {
        margin: -4px 0 0 0;
      }
      .navbar-end-top-bottom {
        @include hundred;
        border-right: 4px solid $primary-light;
      }

      .gradient {
        @include hundred;
        z-index: 200;
        position: absolute;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 5%,
          rgba(255, 255, 255, 0) 34%
        );
      }

      .gradient.rotate180 {
        transform: rotate(180deg);
      }

      .navbar-end-not-active {
        border-right: 4px solid $primary-light;
      }

      .navbar-end-right-bottom-active {
        border-top: 4px solid $primary-light;
        border-radius: 0 30px 0 0;
      }

      .navbar-end-right-top-active {
        border-bottom: 4px solid $primary-light;
        border-radius: 0 0 30px 0;
      }
    }

    .navbar-btn {
      // padding-left: 10px;
      @include flex-center;
      margin: -4px 0 0 0;
      height: 15%;
      // flex: 1 1 15em;
      font-family: "Saira Condensed", sans-serif;
      font-size: 2.4vh;
      font-weight: 300;
      cursor: pointer;

      .navbar-btn-border {
        padding-left: 10px;
        left: 0px;
        display: flex;
        position: absolute;
        height: 15%;
        width: 100%;

        .navbar-btn-left {
          @include hundred;
          position: relative;
        }

        .navbar-btn-left-active {
          border-top: 4px solid $primary-light;
          border-left: 4px solid $primary-light;
          border-bottom: 4px solid $primary-light;
          border-radius: 30px 0 0 30px;
        }

        .navbar-btn-right-active {
          @include hundred;
        }

        .navbar-btn-right-not-active {
          @include hundred;
        }
      }
      .navbar-btn-container {
        @include flex-center;
        transform: rotate(-90deg);

        .cicle {
          margin-right: 0.5em;
          width: 0.7em;
          height: 0.7em;
          border: 3px solid $primary;
          border-radius: 50%;
        }

        .cirle-active {
          background-color: $primary;
        }
      }
    }
  }
}
</style>