<template>
  <div class="card-container" ref="card">
    <div class="card-border" ref="border">
      <div class="img-container">
        <img ref="img" :src="require('@/assets/projects/' + image)" />
      </div>
    </div>
  </div>
</template>
<script>


export default {
  props:["id", "image"],
  data () {
    return {
      // name: "MSC TIME TRACKER",
      // company: "Multy Service Center",
      // desc: "full stack application",
      // image: 'msc.png',
    }
  },
  mounted () {
    this.$refs.card.addEventListener("click", () => {
      this.$emit('openCardPopup', this.id)
    })
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.card-container {
  @include flex-center;
  @include shadow;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  border: 0.5vw solid #e8e8e8;
  margin: 0 1vw;
  z-index: 1000;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    transform: scale(1.05);
    background: linear-gradient(
      90deg,
      rgb(110, 221, 255) 0%,
      rgb(0, 64, 255) 100%
    );
    border: 0.5vw solid #cfcfcf;
  }

  @media only screen and (max-width: 600px) {
    width: 28vw;
    height: 28vw;
    border: 1.5vw solid #e8e8e8;
    margin: -1vw 1vw;
  }

  .card-border {
    @include flex-center;
    width: 96%;
    height: 96%;
    border-radius: 50%;
    background: linear-gradient(
      25deg,
      rgb(0, 255, 234) 0%,
      rgb(0, 65, 255) 100%
    );
    transition: all 0.1s ease-out;

    &:hover {
      box-shadow: 0px 0px 5px rgb(168, 223, 255);
      background: linear-gradient(
        50deg,
        rgb(0, 255, 234) 0%,
        rgb(0, 65, 255) 100%
      );
    }

    .img-container {
      @include flex-center;
      width: 60%;
      height: 100%;

      img {
        width: 8em;
      }
    }
  }
}
</style>