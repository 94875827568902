
<template>
  <div ref="bg1" class="bg-grid-container">
    <div v-for="(r, i) in 5" :key="`a` + i">
      <BgRow :presentPercentage="0.7"></BgRow>
    </div>
    <div v-for="(r, i) in 10" :key="`b` + i">
      <BgRow :presentPercentage="0.8"></BgRow>
    </div>

    <div v-for="(r, i) in 5" :key="`c` + i">
      <BgRow :presentPercentage="0.6"></BgRow>
    </div>
    <div v-for="(r, i) in 3" :key="`d` + i">
      <BgRow :presentPercentage="0.4"></BgRow>
    </div>
  </div>
</template>

<script>
import BgRow from "./BgRow.vue"
export default {
  components: {
    BgRow
  },
  data () {
    return {
      rows: [],
      rowsLength: 20
    }
  },
  mounted () {
    this.gsap.fromTo(this.$refs.bg1,
      {
        x: -300
      }, {
      x: 0,
      duration: 2,
      ease: "expo",
    });
    
    this.gsap.fromTo(this.$refs.bg1, {
      x: 0, 
    }, {
      scrollTrigger: {
        size:40,
        trigger: '.box2',
        start: '18% 25%',
        end: '+=75%',
        scrub: 0.5,
        pin: true,
        // markers: true,
        toggleActions: 'restart pause reverse pause'
      },
      x: -1000,
      duration: 1
    })
  },
  methods: {
    // setBlinking () {
    //   console.log("🚀 ~ Math.random() < .9", Math.random() < .9)
    //   return Math.random() < .9
    // },
    // setPresent () {
    //   return Math.random() < .9
    // },
    // setDuration () {
    //   return (Math.random() * (20 - .4)).toFixed(1)
    // },
    // setOpacity () {
    //   return (Math.random() * (.6 - .1)).toFixed(1)
    // }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.bg-grid-container {
  display: flex;

  animation: bg 3s;

  // @keyframes bg {
  //   from {
  //     opacity: .5;
  //   }
  //   to {
  //     opacity: 1;
  //   }
  // }
}
</style>