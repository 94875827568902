import Vue from 'vue'
import Vuex from 'vuex'
// import { USER_ID, SERVICE_ID, TEMPLATE_ID } from './appProperties'
Vue.use(Vuex)

import emailjs from 'emailjs-com'
const USER_ID = 'user_v4aSoahSVD6Nt1dlSWOXe'
const SERVICE_ID = 'service_hvrqkro'
const TEMPLATE_ID = 'template_w86ruio'
emailjs.init(USER_ID)

export default new Vuex.Store({
  state: {
    isDrawerOpen: false,
    isMessagePopupPresent: false,
    mobileScreen:false,
    userId: USER_ID,
    serviceId: SERVICE_ID,
    templateId: TEMPLATE_ID
  },
  getters: {
    setMessagePopupPresent(state) {
      return state.isMessagePopupPresent
    }
  },
  mutations: {
    setMessagePopupPresent(state) {
      state.isMessagePopupPresent = false
    }
  },
  actions: {
    sendEmail({ commit }, payload) {

      // payload is e.target
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, payload, USER_ID).then(
        (result) => {
          console.log('SUCCESS!', result.status, result.text)
          commit('setMessagePopupPresent')
        },
        (error) => {
          console.log('FAILED...', error)
        }
      )
    }
  }
})
