<template>
  <div ref="messagebutton" class="message-button">
    <span v-if="!scrolled" class="animate__animated animate__backInRight ">MESSAGE ME</span>
    <i
      v-else
      class="material-icons-outlined"
      @mouseover="setMessageOpen"
      @mouseleave="setMessageClose"
      >{{ email }}</i
    >
  </div>
</template>

<script>
export default {
  data () {
    return {
      scrolled: false,
      email: 'email',
      emailOpen: 'drafts'
    }
  },
  methods: {
    setMessageOpen () { this.email = "drafts" },
    setMessageClose () { this.email = "email" },
    mountMessageButton () {
      this.gsap.fromTo(this.$refs.messagebutton, {
        x: 0, y: 200,
        opacity: 0,
      }, {
        x: 0, y: 0,
        opacity: 1,
        delay: 1,
        duration: .3,
        ease: "expo",
      })
    },
  },
  mounted () {
    this.mountMessageButton()

    const messageButton = this.$refs.messagebutton

    window.addEventListener('scroll', () => {
      var height = window.innerHeight / 3.2;

      if (window.scrollY === 0) {
        this.scrolled = false
        messageButton.style.width = "13em"
        messageButton.style.setProperty('left', 'calc((100vw / 2))')
        messageButton.style.setProperty('margin-top', '0')
        messageButton.style.marginLeft = ('-7rem')
      } else if (window.scrollY > (window.innerHeight * 2.8)) {
        this.scrolled = false
        messageButton.style.width = "13em"
        messageButton.style.setProperty('left', 'calc((100vw / 2))')
        messageButton.style.setProperty('margin-top', -height + 'px')
        messageButton.style.marginLeft = ('-7rem')
      } else {
        this.scrolled = true
        messageButton.style.width = "3em"
        messageButton.style.transition = "all .5s"
        messageButton.style.setProperty('top', '100vh)')
        messageButton.style.setProperty('margin-top', '6rem')
        messageButton.style.setProperty('left', '100vw')
        messageButton.style.setProperty('margin-left', '-5rem')
      }
    });
  },
}
</script>


<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import "@/styles/mixin.scss";

.message-button {
  @include flex-center;
  @include shadow;
  cursor: pointer;
  z-index: 900;
  position: fixed;
  width: 13em;
  height: 3em;
  background: $primary;
  //   background: linear-gradient(
  //     0deg,
  //     $primary-dark 0%,
  //     $primary 30%,
  //     $primary 70%,
  //     $primary-dark 100%
  //   );
  border-radius: 2em;
  font-weight: 600;
  font-size: 2.3vh;
  color: white;
  top: 78vh;
  left: calc((100vw / 2) - 6.5em);

  &:hover {
    @include hover;
    background: $primary-dark;
  }
}

i {
  font-size: 4vh;
}
</style>