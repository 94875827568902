
<template>
  <div class="card-tools-container">
    <div v-for="(toolImg, i) in projectTools" :key="`a` + i">
      <img :src="require('@/assets/logos/' + toolImg)" />
    </div>
  </div>
</template>
<script>


export default {
  props: ["toolIndexes"],
  data () {
    return {
      allTools: [
        "vue.png",
        "js.png",
        "java.png",
        "spring.png",
        "html.png",
        "css.png",
        "sass.png",
        "Figma.png",
        "corel.png",
        "photoshop.png",
        "mysql.png",
        "selenium-logo.png",
        "android.png",
        "cucumber.png",
        "aws.jpg",
        "solidworks-logo.png",
        "materialize.png",
        "c.png",
        "net.png",
        "jq.png",
        "php.png",
        "bootstrap.png",
        "heroku.png",
        "node.png",
        "swagger.png",
        "psql.png",
        "react.png",
        "f3.png",
        "fx.png",

      ]
    }
  },
  computed: {
    projectTools () {
      var filteredTools = this.allTools.filter((tool, index) => {
        if (this.toolIndexes.includes(index)) {
          return tool
        }
        index += 1
      })
      return filteredTools
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.card-tools-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  z-index: 20000;

  img {
    width: 2rem;
    height: 2rem;
    margin: 0.2rem 0 0 0.4rem;

    @media only screen and (max-width: 600px) {
      width: 1rem;
      height: 1rem;
      margin: 0 0 0 0.1rem;
    }
    @media only screen and (min-width: 600px) {
      width: 1.4rem;
      height: 1.4rem;
      margin: 0 0 0 0.2rem;
    }
    @media only screen and (min-width: 768px) {
      width: 1.6rem;
      height: 1.6rem;
      margin: 0 0 0 0.3rem;
    }
    @media only screen and (min-width: 992px) {
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 0 0 0.4rem;
    }
    @media only screen and (min-width: 1200px) {
      width: 2rem;
      height: 2rem;
      margin: 0 0 0 0.4rem;
    }
  }
}
</style>