<template>
  <div class="popup-container">
    <div ref="popup" class="popup">
      <i
        ref="close"
        v-if="!isSent"
        @click="closePopup"
        class="material-icons-outlined"
        >{{ "close" }}</i
      >

      <form
        ref="form"
        v-if="!isSent"
        class="contact-form"
        @submit.prevent="onSubmit"
      >
        <div class="popup-title">MESSAGE ME</div>

        <div class="popup-form">
          <div class="popup-label">Name</div>
          <b-field class="input-field">
            <b-input
              v-model.trim="form.name"
              @blur="$v.form.name.$touch()"
              size="is-medium"
              :value="form.name"
              name="from_name"
            >
            </b-input>
            <div v-if="$v.form.name.$error">
              <span class="error_label" v-if="!$v.form.name.required">
                Name is required
              </span>
            </div>
          </b-field>

          <div class="popup-label">Email</div>
          <b-field class="input-field">
            <b-input
              v-model.trim="form.email"
              @blur="$v.form.email.$touch()"
              size="is-medium"
              :value="form.email"
              name="reply_to"
            >
            </b-input>
            <div v-if="$v.form.email.$error">
              <span class="error_label" v-if="!$v.form.email.required">
                Emain is required
              </span>
              <span class="error_label" v-if="!$v.form.email.email">
                Emain is invalid
              </span>
            </div>
          </b-field>

          <div class="popup-label">Subject</div>
          <b-field class="input-field">
            <b-input
              v-model.trim="form.subject"
              @blur="$v.form.subject.$touch()"
              size="is-medium"
              :value="form.subject"
              name="subject"
            ></b-input>
            <div v-if="$v.form.subject.$error">
              <span class="error_label" v-if="!$v.form.subject.required">
                Subject is required
              </span>
            </div>
          </b-field>

          <div class="popup-label">Message</div>
          <b-field id="input-text">
            <b-input
              rows="3"
              type="textarea"
              v-model.trim="form.message"
              @blur="$v.form.message.$touch()"
              size="is-medium"
              :value="form.message"
              name="message"
            >
            </b-input>
          </b-field>
          <div class="error-div">
            <div v-if="$v.form.message.$error">
              <span class="error_label" v-if="!$v.form.message.required">
                Message is required
              </span>
            </div>
          </div>
        </div>

        <button class="send" type="submit" value="Send">
          <span>SEND</span>
        </button>
      </form>

      <div ref="sent" class="sent" v-if="isSent">Thank you!</div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  data () {
    return {
      sent: false,
      error: false,
      form: {
        name: "",
        email: "",
        subject: "",
        message: ""
      }
    }
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      subject: { required },
      message: { required }
    }
  },
  computed: {
    isSent () {
      return this.sent
    }
  },
  methods: {
    trasitionOnThankYou () {
      this.gsap.fromTo(this.$refs.sent, {
        x: 200,
        opacity: 0
      }, {
        x: 0,
        opacity: 1,
        duration: 3,
        ease: 'expo'
      });
    },
    transitionOnClosePopup (delay) {
      this.gsap.fromTo(this.$refs.popup, {
        y: 0,
        opacity: 1
      }, {
        y: 200,
        opacity: 0,
        duration: .3,
        delay: delay,
        ease: 'power3.in'
      });
    },
    closePopup () {
      this.transitionOnClosePopup(0)
      setTimeout(() => {
        this.$store.state.isMessagePopupPresent = false
      }, 500)

    },
    closePopupDelayed () {
      this.transitionOnClosePopup(1.5)
      setTimeout(() => {
        this.$store.state.isMessagePopupPresent = false
      }, 2000)
    },
    onSubmit (e) {
      if (!this.$v.$invalid) {
        console.log("valid")

        this.sent = true
        this.closePopupDelayed()
        this.$store.dispatch('sendEmail', e.target)
        setTimeout(() => { this.sent = false }, 2000)
      } else {
        this.$v.form.name.$touch()
        this.$v.form.email.$touch()
        this.$v.form.subject.$touch()
        this.$v.form.message.$touch()
      }
    }
  },
  mounted () {
    this.gsap.fromTo(this.$refs.popup, {
      y: -200,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: .2,
    });
  },
  destroyed () {
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.popup-container {
  @include flex-center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;

  .popup {
    @include flex-center;
    @include shadow;
    flex-direction: column;
    height: 42rem;
    width: 26rem;
    position: absolute;
    border-radius: 10px;
    border: none;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      25deg,
      rgb(202, 255, 251) 0%,
      rgb(219, 228, 255) 100%
    );

    @media only screen and (max-width: 600px) {
      width: 100vw;
      height: 100vh;
      border-radius: 0px;
    }

    .contact-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 21rem;

      .popup-form {
        width: 100%;

        @media only screen and (max-width: 600px) {
          width: 85vw;
        }
        
        .popup-label {
          font-family: "Saira Condensed", sans-serif;
          font-size: 1.2rem;
          font-weight: 500;
        }

        .input-field {
          min-height: 4.5rem;
          margin: 0;

          @media only screen and (max-width: 600px) {
            height: 2vh !important;
          }
        }

        #input-text {
          margin: 0;
        }
      }

      .send {
        width: 10rem;
        height: 3rem;
        color: white;
        border: none;
        border-radius: 1.5rem;
        padding: 0.5rem;
        font-weight: 600;
        font-size: 1rem;
        cursor: pointer;
        background: $primary-dark;
        background: linear-gradient(
          0deg,
          $primary-dark 0%,
          $primary 30%,
          $primary 70%,
          $primary-dark 100%
        );
      }
    }
  }
}

i {
  position: absolute;
  padding: 0.5rem;
  top: 1rem;
  right: 1rem;
  color: white;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    color: rgb(16, 0, 161);
    background-color: rgb(255, 255, 255, 0.4);
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
  }
}

.popup-title {
  font-size: 2.2rem;
  color: $primary;
}

.error_label {
  color: rgb(17, 2, 150);
  font-size: 1rem;
  font-weight: 500;
  font-family: "Saira Condensed", sans-serif;
}

div.error-div {
  display: flex;
  flex-direction: column;
  height: 2rem;
}
</style>