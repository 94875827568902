<template>
  <section ref="sectionHome">
    <div class="spacer"></div>
    <div class="cntr">
      <div class="left"></div>

      <div class="right center-v">
        <div class="first-line" ref="a" :data-index="1">I’m Dmitry</div>
        <div class="second-line" ref="b" :data-index="2">
          <span>build websites, apps, and create UI/UX design</span>
        </div>
        <div class="third-line" ref="c" :data-index="3">
          combining modern technology and attractive design to enhance business
        </div>
      </div>
    </div>

    <MessageButton @click.native="$store.state.isMessagePopupPresent = true" />

    <div class="spacer"></div>
  </section>
</template>

<script>
import MessageButton from "../components/MessageButton.vue"
export default {
  components: {
    MessageButton
  },
  mounted () {
    this.gsap.fromTo(this.$refs.a, {
      x: 100,
      opacity: 0
    }, {
      x: 0,
      opacity: 1.5,
      duration: .4,
    });

    this.gsap.fromTo(this.$refs.b, {
      x: 100,
      opacity: 0
    }, {
      x: 0,
      opacity: 1.5,
      duration: .4,
      delay: .3
    });

    this.gsap.fromTo(this.$refs.c, {
      x: 100,
      opacity: 0
    }, {
      x: 0,
      opacity: 1.5,
      duration: .4,
      delay: .6
    });
  }
}
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@/styles/mixin.scss";

section {
  @include section-layout;
  height: 100vh;
  background-color: rgb(255, 255, 255);

  .spacer {
    height: 25vh;
  }

  .cntr {
    @include app-width;
    height: 50%;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }
    @media only screen and (min-width: 600px) {
      margin: 0 3vw 0 10vw;
    }
    @media only screen and (min-width: 800px) {
      margin: 0 3vw 0 10vw;
    }
    @media only screen and (min-width: 1000px) {
      margin: 0 5vw 0 12vw;
    }
    @media only screen and (min-width: 1200px) {
      margin: 0 6vw 0 12vw;
    }

    .right {
      @include center-v;
      height: 100%;
      width: 70%;
      padding-left: 5%;
      z-index: 1;

      @media only screen and (max-width: 600px) {
        width: 75%;
        padding-left: 0%;
        align-content: center;
      }
      @media only screen and (min-width: 600px) {
        width: 85%;
        padding-left: 0%;
      }
      @media only screen and (min-width: 800px) {
        width: 75%;
        padding-left: 2%;
      }
      @media only screen and (min-width: 1000px) {
        width: 60%;
        padding-left: 3%;
      }
      @media only screen and (min-width: 1200px) {
        width: 50%;
        padding-left: 5%;
      }

      div.first-line {
        height: 20%;
        font-family: "Saira Condensed", sans-serif;
        font-weight: 300;
        font-size: 4vh;
        color: $primary;
        color: black;
      }
      div.second-line {
        @include center-v;
        height: 50%;
        width: 10em;

        color: $primary;
        font-size: 5.2vh;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;

        @media only screen and (max-width: 600px) {
          font-size: 4.6vh;
        }
        @media only screen and (min-width: 600px) {
          font-size: 4.6vh;
        }
      }
      div.third-line {
        padding-top: 3.6vh;
        height: 30%;
        width: 14em;
        font-family: "Saira Condensed", sans-serif;
        font-weight: 100;
        font-size: 3.5vh;
        color: $gray;
        line-height: 1.3;

        @media only screen and (max-width: 600px) {
          font-size: 3vh;
        }
        @media only screen and (min-width: 600px) {
          font-size: 3vh;
        }
      }
    }
    .left {
      width: 90%;

      @media only screen and (max-width: 600px) {
        width: 0;
      }
      @media only screen and (min-width: 600px) {
        width: 10%;
      }
      @media only screen and (min-width: 800px) {
        width: 25%;
      }
      @media only screen and (min-width: 1000px) {
        width: 40%;
      }
      @media only screen and (min-width: 1200px) {
        width: 50%;
      }
    }
  }
}
</style>
