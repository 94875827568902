import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from './Store/store'

import Main from './pages/Main.vue'
import NotFound from './pages/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Main },
  { path: '/*', component: NotFound }
]

export default new VueRouter({
  mode: 'history',
  routes
})
