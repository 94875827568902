<template>
  <section>
    <h1 class="title">TOOLS</h1>
    <div class="cntr">
      <div class="tool-cntr">
        <div v-for="(c, i) in shuffleCards" :key="i">
          <ToolCard  :name="c[0]" :image="c[1]"></ToolCard>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import ToolCard from '../components/ToolCard.vue'
export default {
  components: { ToolCard },
  data () {
    return {
      cards: [
        ["Vue JS", "vue.png"],
        ["Java Script", "js.png"],
        ["Java", "java.png"],
        ["Spring", "spring.png"],
        ["HTML", "html.png"],
        ["CSS", "css.png"],
        ["Sass", "sass.png"],
        ["Figma", "Figma.png"],
        ["CorelDraw", "corel.png"],
        ["Photoshop", "photoshop.png"],
        ["MySQL", "mysql.png"],
        ["Selenium", "selenium-logo.png"],
        ["Android", "android.png"],
        ["Cucumber", "cucumber.png"],
        ["AWS", "aws.jpg"],
        ["SolidWorks", "solidworks-logo.png"]
      ]
    }
  },
  mounted () {
    
  },
  computed: {
    shuffleCards () {
      var currentIndex = this.cards.length
      var randomIndex

      while (0 !== currentIndex) {

        // Pick a remaining element
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // swap it with the current element.
        [this.cards[currentIndex], this.cards[randomIndex]] = [
          this.cards[randomIndex], this.cards[currentIndex]];
      }

      return this.cards;
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

section {
  @include section-layout;
  @include center-v;
  background-color: rgb(255, 255, 255);
  height: 100vh;

  .title {
    @include section-title;
  }

  .cntr {
    @include flex-center;
    margin: 4vh 18vw;

    .tool-cntr {
      @include grid-center;
      grid-template-columns: repeat(4, 1fr);
      gap: 3.5vh;
      z-index: 100;

      @media only screen and (max-width: 600px) {
        gap: 2.4vh;
      }
      @media only screen and (min-width: 600px) {
        gap: 2.8vh;
      }
      @media only screen and (min-width: 800px) {
        gap: 3.2vh;
      }

      .tool {
        @include flex-center;
        @include shadow;
        width: 7vw;
        height: 7vw;
        font-size: 5em;
      }
    }
  }
}
</style>