
<template>
  <div ref="bg2" class="bg-grid-container">
    <div v-for="(r, i) in 2" :key="`a` + i">
      <BgRow :presentPercentage="0.3"></BgRow>
    </div>
    <div v-for="(r, i) in 2" :key="`b` + i">
      <BgRow :presentPercentage="0.7"></BgRow>
    </div>
    <div v-for="(r, i) in 2" :key="`c` + i">
      <BgRow :presentPercentage="0.9"></BgRow>
    </div>
  </div>
</template>

<script>
import BgRow from "./BgRow.vue"
export default {
  components: {
    BgRow
  },
  data () {
    return {
      rows: [],
      rowsLength: 20
    }
  },
  mounted () {
    this.gsap.fromTo(this.$refs.bg2,
      {
        x: 200,
      }, {
      x: 0,
      duration: 1.4,
      ease: "expo",
    });
    
        this.gsap.fromTo(this.$refs.bg2, {
      x: 0, 
    }, {
      scrollTrigger: {
        size:40,
        trigger: '.box2',
        start: '18% 25%',
        end: '+=75%',
        scrub: 0.5,
        pin: true,
        // markers: true,
        toggleActions: 'restart pause reverse pause'
      },
      x: 500,
      duration: 1
    })
  },
  methods: {
    setBlinking () {
      return Math.random() < .9
    },
    setPresent () {
      return Math.random() < .9
    },
    setDuration () {
      return (Math.random() * (20 - .4)).toFixed(1)
    },
    setOpacity () {
      return (Math.random() * (.6 - .1)).toFixed(1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.bg-grid-container {
  display: flex;
  justify-content: flex-end;

  animation: bg 3s;

  @keyframes bg {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
}
</style>