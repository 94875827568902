<template>
  <div class="sidebar-container">
    <div class="menu-icon">
      <div class="menu-icon-bg"></div>
      <i
        v-if="!sideBarOpen"
        @click="toggleSidebar"
        class="material-icons-outlined"
        >menu</i
      >
      <i v-else @click="toggleSidebar" class="material-icons-outlined">close</i>
    </div>

    <div v-if="sideBarOpen" class="sidebar" ref="sidebar">
      <div
        :class="{ 'active-button': home }"
        class="sidebar-button"
        @click="setNewTab('home')"
      >
        <div class="sidebar-button-name">
          <span>Home</span>
        </div>
      </div>

      <div
        :class="{ 'active-button': projects }"
        class="sidebar-button"
        @click="setNewTab('projects')"
      >
        <div class="sidebar-button-name">
          <span>Projects</span>
        </div>
      </div>

      <div
        :class="{ 'active-button': tools }"
        class="sidebar-button"
        @click="setNewTab('tools')"
      >
        <div class="sidebar-button-name">
          <span>Tools</span>
        </div>
      </div>

      <div
        :class="{ 'active-button': contacts }"
        class="sidebar-button"
        @click="setNewTab('contacts')"
      >
        <div class="sidebar-button-name">
          <span>Contacts</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sideBarOpen: false,
      home: true,
      projects: false,
      tools: false,
      contacts: false,
    }
  },
  watch: {
    '$store.state.isDrawerOpen': function () {
    }
  },
  methods: {
    setAllButtonsInactive () {
      this.home = false
      this.projects = false
      this.tools = false
      this.contacts = false
    },
    setNewTab (activeTab) {
      this.setAllButtonsInactive()

      switch (activeTab) {
        case "home":
          this.home = true
          break;
        case "projects":
          this.projects = true
          break;
        case "tools":
          this.tools = true
          break;
        case "contacts":
          this.contacts = true
          break;
      }

      this.$emit('activeTabInNavBar', activeTab)
      setTimeout(() => {
        this.sideBarOpen = false
      }, 600)
    },
    toggleSidebar () {
      if (this.sideBarOpen) {
        this.transitionOnCloseSideBar()
      }
      else {
        this.transitionOnOpenSideBar()
      }
      this.sideBarOpen = !this.sideBarOpen
      this.$store.state.isDrawerOpen = this.sideBarOpen
    },
    transitionOnOpenSideBar () {
      this.gsap.fromTo(this.$refs.sidebar, {
        x: -100,
        opacity: 0
      }, {
        x: 0,
        opacity: 1,
        duration: .3,
      });
    },
    transitionOnCloseSideBar () {
      this.gsap.fromTo(this.$refs.sidebar, {
        x: 0,
        opacity: 1
      }, {
        x: -100,
        opacity: 0,
        duration: .5,
      });
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/mixin.scss";

.sidebar-container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 7vh;
  z-index: 2000;

  div.sidebar {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 7vh;
    box-shadow: 0px 4px 10px rgb(190, 190, 190);

    .sidebar-button {
      @include flex-center;
      width: 100%;
      height: 20%;

      .sidebar-button-name {
        @include flex-center;
        width: 100%;
        height: 50%;
        transform: rotate(-90deg);
        color: rgb(46, 46, 46);
        font-family: "Saira Condensed", sans-serif;
        font-size: 3.2vh;
        cursor: pointer;

        .cicle {
          margin-right: 0.5em;
          width: 0.7em;
          height: 0.7em;
          border: 3px solid $primary;
          border-radius: 50%;
        }

        .cirle-active {
          background-color: $primary;
        }
      }
    }

    .active-button {
      color: $primary;
      background-color: rgb(240, 246, 252);
      border-right: 3px solid $primary;
    }

    @media only screen and (min-width: 600px) {
      display: none;
    }

    // div.sidebar-links {
    //   width: 100%;
    //   height: 100%;
    // }

    // .navbar-links {
    //   padding-left: 10px;
    //   display: flex;
    //   flex-direction: column-reverse;
    //   height: 100%;
    //   justify-content: center;
    //   align-items: space-between;
    //   color: rgb(46, 46, 46);
    //   z-index: 100;
    // }

    // .navbar-btn {
    //   @include flex-center;
    // }
  }
}

.menu-icon {
  @include flex-center;
  position: fixed;
  width: 7vh;
  height: 7vh;
  z-index: 3000;
  font-size: 24px;

  @media only screen and (min-width: 600px) {
    display: none;
  }

  &:hover .menu-icon-bg {
    opacity: 1;
    background-color: rgb(198, 237, 255);
    width: 60%;
    height: 60%;
  }

  i {
    @include flex-center;
    color: $primary;
    width: 60%;
    height: 60%;
    z-index: 1000;
  }

  .menu-icon-bg {
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
  }
}
</style>