<template>
  <div
    v-tilt="{
      speed: 500,
      perspective: 1200,
      max: 35,
      scale: 1.05,
    }"
    class="tool-card-container"
  >
    <div class="tool-image">
      <img :src="require('@/assets/logos/' + image)" />
    </div>
    <div class="tool-name">{{ name }}</div>
  </div>
</template>
<script>
export default {
  props: ['name', 'image'],
  data () {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.tool-card-container {
  @include shadow;
  @include flex-center;

  flex-direction: column;
  width: 13vh;
  height: 13vh;
  background-color: rgb(255, 255, 255);
  border: 0.9vh solid rgb(248, 248, 248);
  border-radius: 1em;
  z-index: 10;

  @media only screen and (max-width: 600px) {
    width: 9vh;
    height: 9vh;
    border: 0.7vh solid rgb(248, 248, 248);
    border-radius: 0.6em;
  }
  @media only screen and (min-width: 600px) {
    width: 11vh;
    height: 11vh;
    border: 0.8vh solid rgb(248, 248, 248);
    border-radius: 0.8em;
  }

  .tool-image {
    margin-top: 0.6em;
    width: 6vh;
    height: 6vh;

    @media only screen and (max-width: 600px) {
      width: 4vh;
      height: 4vh;
    }
    @media only screen and (min-width: 600px) {
      width: 5vh;
      height: 5vh;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tool-name {
    font-family: "Saira Condensed", sans-serif;
    font-weight: 300;
    font-size: 2vh;
    padding-bottom: -1em;

    @media only screen and (max-width: 600px) {
      font-size: 1.6vh;
    }
    @media only screen and (min-width: 600px) {
      font-size: 1.8vh;
    }
  }
}
</style>