<template>
  <div
    ref="w6"
    class="wheel"
    :style="{ top: currentX + '%', left: currentY + '%' }"
  >
    <svg class="w1" :width="sizeAndStroke" :height="sizeAndStroke">
      <circle
        class="one"
        :cx="halfSizeHalfStroke"
        :cy="halfSizeHalfStroke"
        :r="size / 2"
        :opacity="op1"
        :stroke="randomColor[0]"
        :stroke-width="stroke"
        :style="computedTransformOrigin"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0"
          to="360"
          begin="0"
          repeatCount="indefinite"
          :dur="dur"
        />
      </circle>

      <circle
        class="two"
        :cx="halfSizeHalfStroke"
        :cy="halfSizeHalfStroke"
        :r="size / 2"
        :opacity="op2"
        :stroke="randomColor[1]"
        :stroke-width="stroke"
        :style="computedTransformOrigin"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="360"
          to="0"
          begin="0"
          :dur="dur"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  data () {
    return {
      x: 33, y: 33, size: 50, stroke: 20, dur: 60, op1: .1, op2: .2,
      colors: ["#0014ff", "#3a48e8", "#0e1aab", "#0357ff", "#1ab6eb", "#177fbf", "#0048ff", "#4c00ff"]
    }
  },
  computed: {
    vw () {
      return window.innerWidth;
    },
    vh () {
      return window.innerHeight;
    },
    path () {
      return {
        x1: -300, y1: -100,
        x2: 40 * this.vw / 105, y2: 85 * this.vh / 105,
        x3: -10 * this.vw / 105, y3: 230 * this.vh / 105,
        x4: 50 * this.vw / 105, y4: 320 * this.vh / 105,
      }
    },
    currentX () {
      return this.x
    },
    currentY () {
      return this.y
    },
    sizeAndStroke () {
      var totalSize = parseInt(this.size) + parseInt(this.stroke)
      return totalSize
    },
    halfSizeHalfStroke () {
      return this.size / 2 + this.stroke / 2
    },
    computedTransformOrigin () {
      var dimensions = this.halfSizeHalfStroke + 'px ' + this.halfSizeHalfStroke + 'px'
      return { 'transform-origin': dimensions }
    },
    locationStyle () {
      return { top: this.currentX + 'px', left: this.currentY + 'px' }
    },
    randomColor () {
      var randomIndex1 = Math.floor(Math.random() * (this.colors.length - 0))
      var randomIndex2 = Math.floor(Math.random() * (this.colors.length - 0))
      var twoRandomColors = [this.colors[randomIndex1], this.colors[randomIndex2]]
      return twoRandomColors
    }
  },
  mounted () {
    this.gsap.to(this.$refs.w6, {
      x: this.path.x1, y: this.path.y1,
      duration: 1,
      ease: "expo",
    });

    this.gsap.fromTo(this.$refs.w6, {
      x: this.path.x1, y: this.path.y1,
    }, {
      scrollTrigger: {
        trigger: '.box2',
        start: '7% 25%',
        end: '+=75%',
        scrub: 0.5,
        pin: true,
        // markers: true,
        toggleActions: 'restart pause reverse pause'
      },
      x: this.path.x2, y: this.path.y2,
      duration: .5
    })

    this.gsap.fromTo(this.$refs.w6, {
      x: this.path.x2, y: this.path.y2,
    }, {
      scrollTrigger: {
        trigger: '.box2',
        start: '35% 25%',
        end: '+=70%',
        scrub: 0.5,
        pin: true,
        // markers: true,
        toggleActions: 'restart pause reverse pause'
      },
      x: this.path.x3, y: this.path.y3,
      duration: 1
    })

    this.gsap.fromTo(this.$refs.w6, {
      x: this.path.x3, y: this.path.y3,
    }, {
      scrollTrigger: {
        trigger: '.box2',
        start: '60% 25%',
        end: '+=80%',
        scrub: 0.5,
        pin: true,
        // markers: true,
        toggleActions: 'restart pause reverse pause'
      },
      x: this.path.x4, y: this.path.y4,
      duration: 3
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.wheel {
  position: absolute;

  svg.w1 {
    fill: none;

    .one {
      stroke-dasharray: 8 4 20;
    }

    .two {
      stroke-dasharray: 5 20 30;
    }
  }
}
</style>