<template>
  <div
    class="bg-circle-container"
    :style="{ width: size + 'vh', height: size + 'vh', opacity }"
  >
    <div
      ref="bgCircle"
      v-if="isPresent"
      class="bg-circle"
      :class="{ blinking: isBlinking }"
    ></div>
  </div>
</template>

<script>
export default {
  props: ['isBlinking', 'isPresent', 'duration', 'opacity'],
  data () {
    return {
      size: 3,
      color: "#0014ff",
    }
  },
  mounted () {
    if (this.isPresent) {
      var circle = this.$refs.bgCircle
      circle.style.animationDuration = this.duration + 's'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";

.bg-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 3vh;
  max-width: 3vh;

  .bg-circle {
    width: 60%;
    height: 60%;
    background-color: $primary-light;
    border-radius: 50%;
  }

  .blinking {
    animation: blinker 1s linear infinite;
    @keyframes blinker {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0;
      }
      40% {
        opacity: 0.2;
      }
      50% {
        opacity: 0.5;
      }
      60% {
        opacity: 0.2;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
</style>