<template>
  <div>
    <SideBar v-on:activeTabInNavBar="scrollToNextSection($event)"></SideBar>
    <NavBar v-on:activeTabInNavBar="scrollToNextSection($event)"></NavBar>

    <MessagePopup
      class="message-popup"
      v-if="$store.state.isMessagePopupPresent"
    ></MessagePopup>

    <WM1 v-if="$store.state.mobileScreen"></WM1>
    <WM2 v-if="$store.state.mobileScreen"></WM2>
    <WM3 v-if="$store.state.mobileScreen"></WM3>
    <WM4 v-if="$store.state.mobileScreen"></WM4>

    <div ref="scrollcontainer" id="scroll-container">
      <div class="cntr-bg">
        <BgGrid1></BgGrid1>
        <BgGrid2></BgGrid2>
      </div>

      <Home id="home"></Home>
      <Projects id="projects"></Projects>
      <Tools id="tools"></Tools>
      <Contact id="contacts"></Contact>

      <W2 v-if="!$store.state.mobileScreen"></W2>
      <W3 v-if="!$store.state.mobileScreen"></W3>
      <W4 v-if="!$store.state.mobileScreen"></W4>
      <W5 v-if="!$store.state.mobileScreen"></W5>
      <W6 v-if="!$store.state.mobileScreen"></W6>
      <W7 v-if="!$store.state.mobileScreen"></W7>
      <W8 v-if="!$store.state.mobileScreen"></W8>

    </div>
  </div>
</template>

<script>        
import Home from '../pages/Home.vue'
import Projects from '../pages/Projects.vue'
import Tools from '../pages/Tools.vue'
import Contact from '../pages/Contact.vue'

import SideBar from "../components/SideBar.vue"
import MessagePopup from "../components/MessagePopup.vue"
import NavBar from "../components/NavBar.vue"


import W2 from "../wheels/W2.vue"
import W3 from "../wheels/W3.vue"
import W4 from "../wheels/W4.vue"
import W5 from "../wheels/W5.vue"
import W6 from "../wheels/W6.vue"
import W7 from "../wheels/W7.vue"
import W8 from "../wheels/W8.vue"

import WM1 from "../wheels/WM1.vue"
import WM2 from "../wheels/WM2.vue"
import WM3 from "../wheels/WM3.vue"
import WM4 from "../wheels/WM4.vue"

import BgGrid1 from "../components/BgGrid1.vue"
import BgGrid2 from "../components/BgGrid2.vue"

export default {
  components: {
    MessagePopup, NavBar, SideBar, BgGrid1, BgGrid2,
    W2, W3, W4, W5, W6, W7, W8, 
    WM1, WM2, WM3, WM4,
    Home, Projects, Tools, Contact
  },
  methods: {
    scrollToNextSection (activeTab) {
      this.$el.querySelector("#" + activeTab).scrollIntoView({ behavior: "smooth" });
    },
    scrll () {
      console.log("🚀 ~ scrollcontainer", this.$refs.scrollcontainer)
      this.gsap.to(this.$refs.scrollcontainer, {
        duration: 1, scrollTo: 400
      });
    },
  },
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;500;700;900&family=Saira+Condensed:wght@100;300;600;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

@import "@/styles/mixin.scss";
.message-popup {
  z-index: 1000;
}

#scroll-container {

  .cntr-bg {
    display: flex;
    position: fixed;
    justify-content: space-between;
    margin: 25vh 8vw;
    height: 50%;
    width: 85%;
    max-width: 100%;

    @media only screen and (max-width: 600px) {
      margin: 25vh 8vw 25vh 0;
    }
  }
}
</style>

